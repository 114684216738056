var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template" },
    [
      _c("Loading", { attrs: { visible: _vm.visible } }),
      _vm.data.tempType == "1"
        ? _c("temp-one", { attrs: { data: _vm.data } })
        : _vm._e(),
      _vm.data.tempType == "2"
        ? _c("temp-two", { attrs: { data: _vm.data } })
        : _vm._e(),
      _vm.data.tempType == "5"
        ? _c("temp-four", { attrs: { data: _vm.data } })
        : _vm._e(),
      _vm.data.tempType == "6"
        ? _c("temp-three", { attrs: { data: _vm.data } })
        : _vm._e(),
      _vm.data.tempType == "8"
        ? _c("temp-eight", { attrs: { data: _vm.data } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }