<template>
  <div class="template">
    <Loading :visible="visible" />
    <temp-one v-if="data.tempType == '1'" :data="data" />
    <temp-two v-if="data.tempType == '2'" :data="data" />
    <temp-four v-if="data.tempType == '5'" :data="data" />
    <temp-three v-if="data.tempType == '6'" :data="data" />
    <temp-eight v-if="data.tempType == '8'" :data="data" />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getColumnConfig } from "@/services";
export default {
  components: {
    // 默认模版
    "temp-one": () => import("./template/one.vue"),//日常1
    "temp-two": () => import("./template/two.vue"),//日常2
    "temp-three": () => import("./template/three.vue"), //大促
    "temp-four": () => import("./template/four.vue"),//日常3 无导航
    "temp-eight": () => import("./template/eight.vue"),//日常8 榜单
    Loading,
  },
  data() {
    return {
      data: {
        visible: true,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      try {
        DTKsatc.eventStat({
          name: "moveColumnVisitEvent",
          params: { desc: "运营配置栏目访问", columnId: to.query.id },
        });
      } catch (err) {}
    });
  },
  created() {
    this.visible = true;
    this.getConfig();
  },
  methods: {
    getConfig() {
      const params = { ...this.$route.query };
      getColumnConfig(params).then((res) => {
        this.data = res.data;
        document.title = res.data.title + "-" + document.title;
        this.visible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
